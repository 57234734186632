import { formatDateForMessageDetail } from "@/components/messages/util.ts";
import SourcesList from "@/components/sources/SourcesList.tsx";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import type { Message } from "@/types/message.ts";
import Markdown from "../markdown/markdown.tsx";

const ArticleDetail = ({
  message,
  selectedMessageId: _selectedMessageId,
}: {
  message: Message;
  selectedMessageId: number;
}) => {
  const date = message.articleMessage?.date;
  let formattedDate = "";
  if (date) {
    formattedDate = new Date(date).toLocaleDateString();
  }

  return (
    <div className="flex flex-col p-4 rounded-md border border-gray-300">
      <h1 className="text-lg font-bold">
        {message.articleMessage?.url
          ? (
            <a href={message.articleMessage.url}>
              {message.articleMessage?.title}
            </a>
          )
          : message.articleMessage?.title}
      </h1>
      <div className="flex flex-row gap-2">
        <p className="text-sm text-gray-500">
          {message.articleMessage?.author?.name || "Unknown"}
        </p>
        <p className="text-sm text-gray-500">
          {message.articleMessage?.publisher?.name || "Unknown"}
        </p>
        <p className="text-sm text-gray-500">
          {formattedDate}
        </p>
        <p className="text-sm text-gray-500 flex-grow justify-end">
        </p>
        <div className="text-sm text-gray-500">
          <Accordion type="single" collapsible>
            <AccordionItem value={message.id.toString()}>
              <div className="flex flex-row items-center text-gray-400 gap-2 text-xs justify-end">
                <p>
                  {formatDateForMessageDetail(new Date(message.receivedAt))}
                </p>
                <Separator orientation="vertical" className="h-4" />
                <AccordionTrigger>
                  <p className="">Source</p>
                </AccordionTrigger>
              </div>
              <SourcesList sources={message.sources} />
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div className="pt-2">
        <Markdown>{message.content}</Markdown>
        <div className="flex flex-row mt-2">
          {message.articleMessage?.url && (
            <a href={message.articleMessage.url}>Link</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
