import useSWR from "swr";
import { Source } from "@/types/source.ts";

export const useSources = (messageId: number) => {
  const { data, error, isLoading } = useSWR(`/a/messages/${messageId}/sources`);
  let toReturn: Source[] = [];
  if (!isLoading && data) {
    toReturn = Source.array().parse(data);
  }
  return {
    error: error,
    isLoading: isLoading,
    sources: toReturn,
  };
};

export const useSource = (sourceId: number) => {
  const { data, error, isLoading } = useSWR(`/a/sources/${sourceId}`);
  let toReturn: Source | null = null;
  if (!isLoading && data) {
    toReturn = Source.parse(data);
  }
  return {
    error: error,
    isLoading: isLoading,
    source: toReturn,
  };
};
