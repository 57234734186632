import { isMobileAtom, isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { useContact } from "@/hooks/useContacts.tsx";
import { useAtom, useAtomValue } from "jotai";
import { ChevronLeft, Mail, Phone } from "lucide-react";
import { useParams } from "wouter";
export const Contact = () => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);
  const params = useParams();
  const isMobile = useAtomValue(isMobileAtom);
  const { contact, isLoading, error } = useContact(params[0]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const contactAddresses = contact?.contactAddresses?.map((ca) => {
    let icon = Mail;
    switch (ca.type) {
      case "email":
        icon = Mail;
        break;
      case "phone":
        icon = Phone;
        break;
    }
    return {
      ...ca,
      icon: icon,
    };
  });

  const handleBackButton = () => {
    window.history.back();
  };

  return (
    <SidebarProvider
      open={isNavSidebarOpen}
      onOpenChange={setIsNavSidebarOpen}
    >
      <NavSidebar />
      <main
        className={`flex ${
          isMobile ? "flex-col" : "flex-row"
        } w-full flex-wrap`}
      >
        <NavSideBarTrigger className={`p-5 ${isMobile ? "" : "basis-48"}`} />
        <div className={`flex-1 ${isMobile ? "pt-0" : "grow"}`}>
          <div className="flex flex-col gap-4 p-5">
            {!isMobile && (
              <Button variant="ghost" size="icon" onClick={handleBackButton}>
                <ChevronLeft className="w-8 h-8" />
              </Button>
            )}
            <Card>
              <CardHeader>
                <CardTitle className="flex flex-row items-center gap-2">
                  {contact?.name}
                  <Badge className="w-fit">
                    {contact?.type
                      ? contact?.type?.charAt(0).toUpperCase() +
                        contact?.type?.slice(1)
                      : ""}
                  </Badge>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col gap-2">
                  {contactAddresses?.map((ca) => (
                    <div
                      key={ca.address}
                      className="flex flex-row items-center gap-2"
                    >
                      <ca.icon className="w-4 h-4" />
                      {ca.address}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        {!isMobile && <div className="basis-1/5"></div>}
      </main>
    </SidebarProvider>
  );
};

export default Contact;
