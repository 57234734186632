import {
  selectedStatusFilterAtom,
  selectedThreadAtom,
} from "@/atoms/threadsAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { formatStatus, threadName } from "@/components/threads/util.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { Thread } from "@/types/thread.ts";
import { useAtom } from "jotai";
import { Feather, Mails } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "wouter";
import Markdown from "../markdown/markdown.tsx";

const ThreadList = () => {
  const [selectedStatus, _] = useAtom(selectedStatusFilterAtom);
  const [selectedThread, setSelectedThread] = useAtom(selectedThreadAtom);
  const {
    threadPages,
    isLoading: isThreadsLoading,
    error: threadsError,
    size,
    setSize,
  } = useThreads(selectedStatus);
  const [_location, setLocation] = useLocation();
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const labeledThreads = useMemo(() => threadPages?.flat(), [threadPages]);

  useHotkeys("j", () => {
    let nextThread = selectedThread || labeledThreads[0];
    for (let i = 0; i < labeledThreads.length; i++) {
      if (labeledThreads[i].id === selectedThread?.id) {
        nextThread = labeledThreads[i + 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(nextThread);
  });

  useHotkeys("k", () => {
    let previousThread = selectedThread || labeledThreads[0];
    for (let i = 0; i < labeledThreads.length; i++) {
      if (labeledThreads[i].id === selectedThread?.id) {
        previousThread = labeledThreads[i - 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(previousThread);
  });

  useHotkeys("enter", () => {
    if (selectedThread) {
      handleClick(selectedThread);
    }
  });

  const handleClick = useCallback(
    (thread: Thread) => {
      setSelectedThread(thread);
      setLocation(`/thread/${thread.id}`);
    },
    [setSelectedThread],
  );

  const formatDateForThreadList = useCallback((date: Date): string => {
    const now = new Date();
    if (now.getTime() - date.getTime() < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (now.getFullYear() === date.getFullYear()) {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    }
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }, []);

  const handleScroll = () => {
    const viewport = scrollAreaRef.current?.querySelector(
      "[data-radix-scroll-area-viewport]",
    );
    if (!viewport) return;

    const { scrollTop, scrollHeight, clientHeight } =
      viewport as HTMLDivElement;
    if (scrollHeight - scrollTop - clientHeight < 100) {
      const isLoadingMore = isThreadsLoading ||
        (size > 0 && threadPages && !threadPages[size - 1]);
      if (!isLoadingMore) {
        setSize(size + 1);
      }
    }
  };

  useEffect(() => {
    const viewport = scrollAreaRef.current?.querySelector(
      "[data-radix-scroll-area-viewport]",
    );
    if (!viewport) return;

    viewport.addEventListener("scroll", handleScroll);

    return () => {
      viewport.removeEventListener("scroll", handleScroll);
    };
  }, [scrollAreaRef.current, threadPages]);

  if (threadsError) {
    return <Error error={threadsError} />;
  }

  if (isThreadsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col h-full">
      <ScrollArea
        ref={scrollAreaRef}
        className="flex-grow overflow-y-hidden"
      >
        <div className="flex flex-col ">
          {labeledThreads.map((thread) => (
            <div
              key={thread.id}
              className={`flex flex-col gap-2`}
              onClick={() => handleClick(thread)}
            >
              <div
                className={`flex flex-col gap-2 p-2 rounded-md hover:bg-gray-100 cursor-pointer ${
                  thread.id === selectedThread?.id ? "bg-gray-100" : ""
                }`}
              >
                <div className="flex flex-row justify-between">
                  <h3 className="text-sm font-semibold basis-3/4 text-ellipsis overflow-hidden">
                    {threadName(thread)}
                  </h3>
                  <div className="text-sm text-gray-500 basis-1/4 text-right">
                    <div className="flex flex-row items-center justify-end gap-2 text-xs text-gray-400">
                      <div className="flex-none">
                        {thread.status !== selectedStatus
                          ? formatStatus(thread.status)
                          : ""}
                      </div>
                      {thread.formattedLabels?.filter((label) => label).map((
                        label,
                      ) => (
                        <Badge
                          key={label.name}
                          className="text-xs text-gray-500"
                          variant="outline"
                          style={{
                            backgroundColor: label.backgroundColor,
                            color: label.textColor,
                          }}
                        >
                          {label.name}
                        </Badge>
                      ))}
                      <Badge
                        className="text-xs text-gray-500"
                        variant="outline"
                      >
                        {thread.type[0].toUpperCase() + thread.type.slice(1)}
                      </Badge>
                      <Mails className="w-4 h-4" />
                      <Feather className="w-4 h-4" />
                      <div className="flex-none">
                        {formatDateForThreadList(thread.lastReceivedAt)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-500 overflow-hidden line-clamp-2">
                  <Markdown>{thread.summary}</Markdown>
                </div>
              </div>
              <Separator className="h-[1px] bg-gray-200" />
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default ThreadList;
