import { Contact } from "@/types/contact.ts";
import { z } from "zod";

export const ArticleMessage = z.object({
  author: Contact.nullable(),
  //content: z.string(),
  date: z.string().transform((date) => {
    return new Date(date);
  })
    .nullable(),
  id: z.number(),
  publisher: Contact.nullable(),
  //sources: z.array(z.number()),
  title: z.string(),
  url: z.string().nullable(),
});

export type ArticleMessage = z.infer<typeof ArticleMessage>;
