import { Textarea } from "@/components/ui/textarea.tsx";
import React, {
  ChangeEvent,
  KeyboardEvent,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";

interface ChatTextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "rows"> {
  initialRows?: number;
  onEnterKeyPressed?: (value: string) => void;
}

export const ChatTextarea: React.FC<ChatTextareaProps> = ({
  className = "",
  initialRows = 2,
  onEnterKeyPressed,
  isDisabled = false,
  placeholder = "Type your message here...",
  defaultValue = "",
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        `${textareaRef.current.scrollHeight}px`;
    }
    setValue(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onEnterKeyPressed?.(value);
      setValue("");
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Textarea
      disabled={isDisabled}
      className={`resize-none transition-all duration-200 ${className}`}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      onInput={handleInputChange}
      ref={textareaRef}
      rows={initialRows}
      value={value}
      {...props}
    />
  );
};
