import { z } from "zod";

export const ContactAddress = z.object({
  id: z.number(),
  contactId: z.number(),
  address: z.string(),
  type: z.enum(["email", "phone", "home", "work"]),
});

export const Contact = z.object({
  id: z.number(),
  name: z.string(),
  type: z.enum(["person", "company", "assistant", "user"]),
  contactAddresses: z.array(ContactAddress).optional(),
});

export type Contact = z.infer<typeof Contact>;
export type ContactAddress = z.infer<typeof ContactAddress>;
