import { isMobileAtom, isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import ThreadCards from "@/components/threads/ThreadCards.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import { useAtom, useAtomValue } from "jotai";
import { useCallback } from "react";

export const Home = () => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);
  const { userContext } = useCurrentUserContext();
  const isMobile = useAtomValue(isMobileAtom);

  const handleSidebarOpenChange = useCallback((open: boolean) => {
    setIsNavSidebarOpen(open);
  }, [setIsNavSidebarOpen]);

  return (
    <SidebarProvider
      open={isNavSidebarOpen}
      onOpenChange={handleSidebarOpenChange}
    >
      <NavSidebar className="" />
      <main className={`flex ${isMobile ? "flex-col" : "flex-row"} w-full`}>
        <NavSideBarTrigger className={`p-5 ${isMobile ? "" : "basis-48"}`} />
        <div
          className={`flex-1 grow flex flex-col gap-4 p-6 ${
            isMobile ? "pt-0" : ""
          }`}
        >
          <div className="text-lg text-gray-500">
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </div>
          <h1 className="text-2xl font-bold">
            Good morning, {userContext?.user.contact.name}!
          </h1>
          <div className="flex">
            <div className="flex flex-col gap-2">
              <div className="text-lg font-semibold">
                You have new updates in your inbox!
              </div>
              <div className="text-gray-500">
                Check out shipping and appointment confirmations, work emails,
                and some social notifications. Plus, there are bank statements,
                renewal notices, and a few exclusive offers waiting for you!
              </div>
            </div>
          </div>
          <ThreadCards />
        </div>
      </main>
    </SidebarProvider>
  );
};
export default Home;
