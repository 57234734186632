import { isMobileAtom, isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import DocDetail from "@/components/sources/DocDetail.tsx";
import EmailDetail from "@/components/sources/EmailDetail.tsx";
import MemoryDetail from "@/components/sources/MemoryDetail.tsx";
import { Button } from "@/components/ui/button.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { useSource } from "@/hooks/useSources.tsx";
import { useAtom, useAtomValue } from "jotai";
import { ChevronLeft } from "lucide-react";
import { useLocation, useParams } from "wouter";

export const Source = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useAtom(isNavSidebarOpenAtom);
  const isMobile = useAtomValue(isMobileAtom);
  const sourceId = useParams()["sourceId"];
  const [_, setLocation] = useLocation();

  if (!sourceId) {
    setLocation("/", { replace: true });
    return null;
  }

  const { error, isLoading, source } = useSource(parseInt(sourceId));

  function handleSidebarOpenChange(open: boolean) {
    setIsSidebarOpen(open);
  }

  function handleBackButton() {
    window.history.back();
  }

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading || !source) {
    return <Loader />;
  }

  let componentBasedOnSourceType: React.ReactNode;
  switch (source.type) {
    case "email":
      componentBasedOnSourceType = <EmailDetail source={source} />;
      break;
    case "document":
      componentBasedOnSourceType = <DocDetail source={source} />;
      break;
    case "memory":
      componentBasedOnSourceType = <MemoryDetail source={source} />;
      break;
    default:
      componentBasedOnSourceType = null;
  }

  return (
    <SidebarProvider
      open={isSidebarOpen}
      onOpenChange={handleSidebarOpenChange}
    >
      <NavSidebar />
      <main className="flex flex-row w-full flex-wrap">
        <NavSideBarTrigger />
        <div className="basis-3/5 flex flex-row p-4">
          {!isMobile && (
            <Button variant="ghost" size="icon" onClick={handleBackButton}>
              <ChevronLeft className="w-8 h-8" />
            </Button>
          )}
          {componentBasedOnSourceType}
        </div>
      </main>
    </SidebarProvider>
  );
};

export default Source;
