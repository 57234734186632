import { axiosPostFetch } from "@/lib/fetchers.ts";
import type { NewMessageReq } from "@/types/chat.ts";
import { Message } from "@/types/message.ts";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export const useMessages = (threadId: number) => {
  const { trigger, isMutating: isSending } = useSWRMutation(
    `/a/threads/${threadId}/messages`,
    axiosPostFetch<NewMessageReq, Message[]>,
  );

  const { data, isLoading, error, mutate } = useSWR<Message[]>(
    `/a/threads/${threadId}/messages`,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: false,
      refreshInterval: isSending ? 0 : 5000,
    },
  );

  const sendMessage = async (text: string) => {
    await trigger(
      { text },
      {
        optimisticData: [
          ...(data ?? []),
          {
            id: Math.random(),
            author: { type: "user" },
            content: text,
            isRead: true,
          },
        ],
        rollbackOnError: true,
      },
    );
    await mutate();
  };

  return {
    messages: data,
    isLoading,
    error,
    isSending,
    sendMessage,
  };
};
