import ReactMarkdown from "markdown-to-jsx";
import { Link } from "wouter";

const Markdown = (
  { children, options = {} }: { children: React.ReactNode; options?: any },
) => {
  const defaultOptions = {
    ...options,
    forceBlock: true,
    overrides: {
      ul: {
        props: {
          className: "list-disc",
        },
      },
      a: {
        component: MarkdownLink,
      },
    },
  };

  const content = Array.isArray(children)
    ? children.join("")
    : String(children || "");

  return (
    <ReactMarkdown options={defaultOptions}>
      {content}
    </ReactMarkdown>
  );
};

const MarkdownLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const entityItems = href.split(":");
  const entity = entityItems[0];
  const entityId = entityItems[1];

  let appHref = href;
  switch (entity) {
    case "contact":
      appHref = `/contacts/${entityId}`;
      break;
  }

  return (
    <Link
      href={appHref}
      className="underline"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </Link>
  );
};

export default Markdown;
