// import { useSources } from "@/components/hooks/useSources.tsx";
import { formatDateForMessageDetail } from "@/components/messages/util.ts";
import { AccordionContent } from "@/components/ui/accordion.tsx";
import { Message } from "@/types/message.ts";
import { Source } from "@/types/source.ts";
import { Document } from "@/types/sources/document.ts";
import { Brain, Mail, Paperclip } from "lucide-react";
import { Link } from "wouter";

interface SourcesListProps {
  //   message: Message;
  sources: Message["sources"];
}

const SourcesList: React.FC<SourcesListProps> = ({ sources }) => {
  //   const { error, isLoading, sources } = useSources(message.id);

  //   if (error) {
  //     return <div>Error</div>;
  //   }

  //   if (isLoading) {
  //     return <div>Loading...</div>;
  //   }

  if (!sources) {
    return null;
  }

  const displaySources = sources.map((source: Source) => {
    let displaySource: any = {
      id: source.id,
    };
    switch (source.type) {
      case "document":
        displaySource = {
          ...displaySource,
          icon: Paperclip,
          name: source.document?.name,
          date: source.document?.createdAt,
        };
        break;
      case "email":
        displaySource = {
          ...displaySource,
          icon: Mail,
          name: source.email?.subject,
          date: source.email?.receivedAt,
          attachments: source.email?.attachments,
        };
        break;
      case "memory":
        displaySource = {
          ...displaySource,
          icon: Brain,
          name: source.memory?.content,
          date: source.memory?.createdAt,
        };
        break;
    }

    return displaySource;
  });

  if (displaySources.length === 0) {
    return null;
  }

  const handleDownload = (binaryString: any, fileName: string) => {
    console.log({ binaryString, fileName });
    // const len = binaryString.data.length;
    // const bytes = new Uint8Array(len);
    // for (let i = 0; i < len; i++) {
    //   bytes[i] = binaryString.data[i];
    // }
    // console.log({ binaryString, fileName, len, bytes });

    // const blob = new Blob([bytes], { type: "application/octet-stream" });
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = fileName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  return (
    <AccordionContent className="flex flex-col gap-2 text-black text-xs w-full">
      {displaySources.map((source) => {
        return (
          <div key={source.id} className="flex flex-row items-center gap-2">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <source.icon className="w-4 h-4" />
                <Link href={`/source/${source.id}`}>{source.name}</Link>
              </div>
              {source.attachments && (source.attachments.length > 0) &&
                (source.attachments.map((attachment: Document) => {
                  return (
                    <div
                      key={attachment.id}
                      className="flex flex-row items-center gap-2 pl-4 pt-1"
                    >
                      <Paperclip className="w-4 h-4" />
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(
                            attachment.content,
                            attachment.name,
                          );
                        }}
                      >
                        {attachment.name}
                      </a>
                    </div>
                  );
                }))}
            </div>
            <div className="ml-auto">
              {formatDateForMessageDetail(new Date(source.date))}
            </div>
          </div>
        );
      })}
    </AccordionContent>
  );
};

export default SourcesList;
