import { isMobileAtom, isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import {
  selectedStatusFilterAtom,
  selectedThreadAtom,
} from "@/atoms/threadsAtoms.ts";
import { ChatTextarea } from "@/components/ChatTextarea.tsx";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import ThreadList from "@/components/threads/ThreadList.tsx";
import { statuses } from "@/components/threads/util.ts";
import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import { ThreadStatus } from "@/types/thread.ts";
import { useAtom, useAtomValue } from "jotai";
import { useCallback } from "react";

export const Threads = () => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);
  const [selectedStatus, setSelectedStatus] = useAtom(selectedStatusFilterAtom);
  const [_, setSelectedThread] = useAtom(selectedThreadAtom);
  const { threadPages } = useThreads();
  const { userContext } = useCurrentUserContext();

  const isMobile = useAtomValue(isMobileAtom);

  const handleStatusChange = useCallback((status: string) => {
    setSelectedStatus(ThreadStatus.enum[status]);
    setSelectedThread(threadPages?.[0]?.[0] || null);
  }, [setSelectedThread, threadPages]);

  const handleSidebarOpenChange = useCallback((open: boolean) => {
    setIsNavSidebarOpen(open);
  }, [setIsNavSidebarOpen]);

  return (
    <SidebarProvider
      open={isNavSidebarOpen}
      onOpenChange={handleSidebarOpenChange}
    >
      <NavSidebar />
      <main
        className={`flex ${
          isMobile ? "flex-col" : "flex-row"
        } w-full flex-wrap`}
      >
        <NavSideBarTrigger className={`p-5 ${isMobile ? "" : "basis-48"}`} />
        <div
          className={`flex-1 p-5 grow w-full flex flex-col ${
            isMobile ? "pt-0" : ""
          }`}
        >
          <div className="flex flex-col h-screen gap-2">
            <div className="flex flex-col gap-4 basis-1/8">
              <h1
                className={`text-4xl fond-extrabold`}
              >
                Threads
              </h1>
              <Tabs defaultValue={selectedStatus}>
                <TabsList className="rounded-full">
                  {statuses.map((status) => (
                    <TabsTrigger
                      key={status.value}
                      value={status.value}
                      onClick={() => handleStatusChange(status.value)}
                      className="rounded-full"
                    >
                      {status.label}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
              <Separator className="h-[1px] bg-gray-200" />
            </div>
            <div className="flex-grow overflow-y-hidden">
              <ThreadList />
            </div>
            <div className="basis-1/8 flex flex-row gap-2 p-4 items-center">
              <Avatar>
                <AvatarFallback className="bg-slate-500 text-white">
                  {userContext?.user.contact.name.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <ChatTextarea
                className="flex-grow pt-4"
                onEnterKeyPressed={() => {}}
              />
            </div>
          </div>
        </div>
        {!isMobile && <div className="basis-1/5"></div>}
      </main>
    </SidebarProvider>
  );
};
export default Threads;
