import { isMobileAtom, isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import ThreadDetail from "@/components/threads/ThreadDetail.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation, useRoute } from "wouter";
export const Thread = () => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);
  const [_location, setLocation] = useLocation();
  const [matchThread, paramsThread] = useRoute("/thread/:threadId");
  const isMobile = useAtomValue(isMobileAtom);

  useEffect(() => {
    if (!matchThread || !paramsThread || !paramsThread.threadId) {
      setLocation("/");
    }
  }, [matchThread, paramsThread, setLocation]);

  useHotkeys("escape", () => {
    window.history.back();
  });

  if (!matchThread || !paramsThread || !paramsThread.threadId) {
    return null;
  }

  function handleSidebarOpenChange(open: boolean) {
    setIsNavSidebarOpen(open);
  }

  return (
    <SidebarProvider
      open={isNavSidebarOpen}
      onOpenChange={handleSidebarOpenChange}
    >
      <NavSidebar />
      <main
        className={`flex ${
          isMobile ? "flex-col" : "flex-row"
        } w-full flex-wrap`}
      >
        <NavSideBarTrigger className={`p-5 ${isMobile ? "" : "basis-48"}`} />
        <div className={`flex-1 ${isMobile ? "pt-0" : "grow"}`}>
          <ThreadDetail
            selectedMessageId={0}
            threadId={parseInt(paramsThread.threadId)}
          />
        </div>
        {!isMobile && <div className="basis-1/5"></div>}
      </main>
    </SidebarProvider>
  );
};

export default Thread;
