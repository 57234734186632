import { selectedThreadAtom } from "@/atoms/threadsAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { formatDateForThreadList } from "@/components/threads/util.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { Thread, ThreadStatus } from "@/types/thread.ts";
import { useAtom } from "jotai";
import { Eclipse, Mail } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "wouter";
import Markdown from "../markdown/markdown.tsx";

const ThreadCards = () => {
  const [selectedThread, setSelectedThread] = useAtom(selectedThreadAtom);
  const { threadPages, isLoading: isThreadsLoading, error: threadsError } =
    useThreads(ThreadStatus.enum["to-review"]);
  const [_location, setLocation] = useLocation();

  useHotkeys("j", () => {
    let nextThread = selectedThread || filteredThreads[0];
    for (let i = 0; i < filteredThreads.length; i++) {
      if (filteredThreads[i].id === selectedThread?.id) {
        nextThread = filteredThreads[i + 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(nextThread);
  });

  useHotkeys("k", () => {
    let previousThread = selectedThread || filteredThreads[0];
    for (let i = 0; i < filteredThreads.length; i++) {
      if (filteredThreads[i].id === selectedThread?.id) {
        previousThread = filteredThreads[i - 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(previousThread);
  });

  useHotkeys("enter", () => {
    if (selectedThread) {
      handleClick(selectedThread);
    }
  });

  const handleClick = useCallback((thread: Thread) => {
    setSelectedThread(thread);
    setLocation(`/thread/${thread.id}`);
  }, [setSelectedThread]);

  const chooseBackgroundImage = (thread: Thread) => {
    let imageUrl = "/card_backgrounds/";
    switch (thread.type) {
      case "article": {
        const randomNum = Math.floor(Math.random() * 3) + 1;
        imageUrl += `news-${randomNum}.jpg`;
        break;
      }
      case "people": {
        const randomNum = Math.floor(Math.random() * 6) + 1;
        imageUrl += `people-${randomNum}.jpg`;
        break;
      }
      case "store": {
        const randomNum = Math.floor(Math.random() * 2) + 1;
        imageUrl += `stores-${randomNum}.jpg`;
        break;
      }
      case "app": {
        imageUrl += `apps-1.jpg`;
        break;
      }
      default: {
        imageUrl += "home_pool.jpg";
      }
    }
    return imageUrl;
  };

  const filteredThreads = useMemo(() => {
    return threadPages?.flatMap((page) => {
      return page.map((thread) => {
        return {
          ...thread,
          backgroundImage: chooseBackgroundImage(thread),
        };
      });
    });
  }, [threadPages]);

  if (threadsError) {
    return <Error error={threadsError} />;
  }

  if (isThreadsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-row gap-2 flex-wrap">
      {filteredThreads.map((thread) => (
        <div
          key={thread.id}
          className={`p-2 border-2 ${
            selectedThread?.id === thread.id ? "border-blue-500" : "border-none"
          } rounded-lg bg-white`}
        >
          <Card
            className="relative w-80 h-80 p-2 bg-cover bg-center"
            style={{ backgroundImage: `url(${thread.backgroundImage})` }}
            onClick={() => handleClick(thread)}
          >
            <div className="absolute inset-0 bg-black opacity-50 rounded-lg">
            </div>
            <CardContent className="relative flex flex-col justify-stretch">
              <div className="flex-basis-1/3 flex flex-row gap-2 text-gray-400">
                <div className="grow text-sm">
                  {thread.formattedLabels?.filter((label) => label).map((
                    label,
                  ) => (
                    <Badge
                      key={label.name}
                      className="bg-gray-200 text-black mr-1"
                      style={{
                        backgroundColor: label.backgroundColor,
                        color: label.textColor,
                      }}
                    >
                      {label.name}
                    </Badge>
                  ))}
                  <Badge className="bg-gray-200 text-black">
                    {thread.type.charAt(0).toUpperCase() + thread.type.slice(1)}
                  </Badge>
                </div>
                <Eclipse className="w-4 h-4" />
              </div>
              <div className="h-40"></div>
              <div className="flex-none h-1/3 gap-2 flex flex-col text-white">
                <div className="flex flex-row gap-1 items-center">
                  <h4 className="text-md font-bold basis-4/5 text-ellipsis overflow-hidden whitespace-nowrap">
                    {thread.title}
                  </h4>
                  <Mail className="w-2 h-4 basis-1/5" />
                </div>
                <div className="text-sm overflow-hidden line-clamp-4">
                  {formatDateForThreadList(thread.lastReceivedAt)}:{" "}
                  <Markdown>{thread.summary}</Markdown>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ThreadCards;
