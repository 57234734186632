import Markdown from "@/components/markdown/markdown.tsx";
import ArticleDetail from "@/components/messages/ArticleDetail.tsx";
import { formatDateForMessageDetail } from "@/components/messages/util.ts";
import SourcesList from "@/components/sources/SourcesList.tsx";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion.tsx";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { createBatchManager, useReadState } from "@/hooks/useReadState.tsx";
import { axiosPostFetch } from "@/lib/fetchers.ts";
import { cn } from "@/lib/utils.ts";
import { Message } from "@/types/message.ts";
import { useEffect } from "react";

const readStateBatch = createBatchManager((ids: number[]) => {
  console.log(`batching read messages: ${ids.join(", ")}`);
  axiosPostFetch(`/a/messages/read`, {
    arg: { messageIds: ids },
  });
});

const MessageDetail = ({
  message,
}: {
  message: Message;
  selectedMessageId: number;
}) => {
  const isUser = message.author?.type === "user";
  const [ref, isRead] = useReadState({ initialValue: message.isRead });

  useEffect(() => {
    if (isRead && !message.isRead) {
      readStateBatch.add(message.id);
      message.isRead = true;
    }
  }, [isRead]);

  useEffect(() => {
    return () => readStateBatch.cleanup();
  }, []);

  if (message.type === "article" && message.articleMessage) {
    return (
      <div ref={ref}>
        <ArticleDetail selectedMessageId={message.id} message={message} />
      </div>
    );
  }

  return (
    <div ref={ref} className={cn(["p-4 rounded-md flex flex-row gap-4"])}>
      <div className="p-1">
        {!isUser && (
          <Avatar className="w-6 h-6">
            <AvatarImage src="/ai_avatar.png" />
            <AvatarFallback className="bg-white text-black">
              {message.author?.name[0] || ""}
            </AvatarFallback>
          </Avatar>
        )}
      </div>
      <div className="w-full">
        <div
          className={cn([
            "p-2 pt-0 w-5/6",
            isUser ? "p-4 bg-gray-100 rounded-2xl ml-auto" : "",
          ])}
        >
          <Markdown>{message.content}</Markdown>
        </div>
        <Accordion type="single" collapsible>
          <AccordionItem value={message.id.toString()} className="border-0">
            <div className="flex flex-row items-center text-gray-400 gap-2 text-xs justify-end mt-3">
              {message.sources && message.sources.length !== 0 && (
                <>
                  <AccordionTrigger className="p-0">
                    <p className="">Source</p>
                  </AccordionTrigger>
                  <Separator orientation="vertical" className="h-4" />
                </>
              )}
              <p>{formatDateForMessageDetail(new Date(message.receivedAt))}</p>
            </div>
            <SourcesList sources={message.sources} />
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default MessageDetail;
