import { Category } from "@/types/category.ts";
import { MessageType } from "@/types/message.ts";
import { z } from "zod";

export const ThreadStatus = z.enum([
  "archived",
  "in-progress",
  "to-review",
  "completed",
  "snoozed",
]);

export const Thread = z.object({
  categories: z.array(Category).optional(),
  id: z.number(),
  status: ThreadStatus,
  summary: z.string().nullable(),
  title: z.string(),
  type: MessageType,
  lastReceivedAt: z.coerce.date(),
  labels: z.array(z.string()).optional(),
});

export type Thread = z.infer<typeof Thread>;
export type ThreadStatus = z.infer<typeof ThreadStatus>;
