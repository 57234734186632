import { Suspense } from "react";
import { SWRConfig } from "swr";
import { Route, Router, Switch, useLocation } from "wouter";

import { isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { Loader } from "@/components/Loader.tsx";
import { useIsMobile } from "@/hooks/use-mobile.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import { axiosFetcher } from "@/lib/fetchers.ts";
import { Admin } from "@/views/Admin.tsx";
import { Auth } from "@/views/Auth.tsx";
import { Contact } from "@/views/Contact.tsx";
import { Home } from "@/views/Home.tsx";
import { NotFound } from "@/views/NotFound.tsx";
import { Source } from "@/views/Source.tsx";
import { Thread } from "@/views/Thread.tsx";
import { Threads } from "@/views/Threads.tsx";
import { useAtom } from "jotai";
import { useHotkeys } from "react-hotkeys-hook";

export const App = () => {
  const [, setLocation] = useLocation();
  const { userContext, isLoading } = useCurrentUserContext();
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);

  useHotkeys("b", () => {
    setIsNavSidebarOpen(!isNavSidebarOpen);
  });

  // register size change to update isMobile atom
  useIsMobile();

  if (isLoading) {
    return <Loader />;
  }

  if (!userContext) {
    return <Auth />;
  }

  return (
    <SWRConfig
      value={{
        onError: (error) => {
          if (error.status === 401 || error.status === 403) {
            setLocation("/auth", { replace: true });
          }
        },
        fetcher: axiosFetcher,
        refreshInterval: 900000,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      }}
    >
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route component={Home} path="/" />
            <Route component={Auth} path="/auth" />
            <Route component={Threads} path="/threads" />
            <Route component={Home} path="/home" />
            <Route component={Thread} path="/thread/:threadId" />
            <Route component={Source} path="/source/:sourceId" />
            <Route component={Admin} path="/admin" />
            <Route component={Contact} path="/contacts/:contactId" />
            <Route component={NotFound} path="*" />
          </Switch>
        </Suspense>
      </Router>
    </SWRConfig>
  );
};
