import { isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { selectedStatusFilterAtom } from "@/atoms/threadsAtoms.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  SidebarTrigger,
} from "@/components/ui/sidebar.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import axios from "axios";
import { useAtomValue } from "jotai";
import {
  Brain,
  ChevronDown,
  ChevronsLeft,
  Contact,
  Home,
  LogOut,
  Menu,
  MessagesSquare,
  RefreshCcw,
  Settings,
  Soup,
} from "lucide-react";
import React from "react";
import { Link, useLocation } from "wouter";

const NavSidebar = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className, children, ...props }, ref) => {
  const selectedStatusFilter = useAtomValue(selectedStatusFilterAtom);
  const { userContext, mutate } = useCurrentUserContext();
  const { mutate: mutateThreads } = useThreads(selectedStatusFilter);
  const [, setLocation] = useLocation();
  const navItems = [
    {
      label: "Home",
      href: "/",
      icon: Home,
    },
    {
      label: "Threads",
      href: "/threads",
      icon: MessagesSquare,
    },
    {
      label: "Relationships",
      href: "/relationships",
      icon: Contact,
    },
    {
      label: "Memories",
      href: "/memories",
      icon: Brain,
    },
    {
      label: "Settings",
      href: "/settings",
      icon: Settings,
    },
  ];

  if (userContext?.user.isAdmin) {
    navItems.push({
      label: "Admin",
      href: "/admin",
      icon: Soup,
    });
  }

  const dropdownItems = [
    {
      label: "Incremental Sync",
      icon: RefreshCcw,
      url: "/a/users/force_sync",
    },
    {
      label: "Hard Reset Sync",
      icon: RefreshCcw,
      url: "/a/users/reset_sync",
    },
  ];

  const handleDropdownItemClick = async (item) => {
    await axios.post(item.url, { userId: userContext?.user.id });
    mutateThreads();
  };

  const handleLogout = async () => {
    await axios.post("/a/users/logout", { userId: userContext?.user.id });
    mutate();
    setLocation("/auth");
  };

  return (
    <Sidebar className={`p-2 shadow-xl ${className}`} ref={ref} {...props}>
      <SidebarHeader>
        <div className="flex items-center gap-2">
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton className="flex flex-row items-center">
                    <div className="w-6 h-6">
                      <img
                        src="/favicon.png"
                        alt="logo"
                        className="grayscale hover:grayscale-0 brightness-110 contrast-200"
                      />
                    </div>
                    <div className="text-xl">
                      {userContext?.user.contact.name.split(" ")[0]}
                    </div>
                    <ChevronDown />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="p-2">
                  {dropdownItems.map((item) => (
                    <DropdownMenuItem
                      key={item.label}
                      onSelect={() => handleDropdownItemClick(item)}
                    >
                      <div className="flex items-center gap-2">
                        <item.icon />
                        <div>
                          {item.label}
                        </div>
                      </div>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
          <SidebarTrigger className="w-12 h-12">
            <ChevronsLeft strokeWidth={1} />
          </SidebarTrigger>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu className="pt-4 gap-4">
              {navItems.map((item) => (
                <SidebarMenuItem
                  key={item.href}
                  className="hover:bg-gray-100 rounded-full p-2"
                >
                  <SidebarMenuButton asChild>
                    <Link
                      href={item.href}
                      className="flex flex-row items-center gap-4"
                    >
                      <item.icon />
                      <div className="text-lg">
                        {item.label}
                      </div>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
              <SidebarMenuItem
                key="logout"
                className="hover:bg-gray-100 rounded-full p-2"
              >
                <SidebarMenuButton asChild onClick={handleLogout}>
                  <div className="flex flex-row items-center gap-4">
                    <LogOut />
                    <div className="text-lg">
                      Logout
                    </div>
                  </div>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {children}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="text-sm text-muted-foreground">
        Powered by Big Basin Labs
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
});
NavSidebar.displayName = "NavSidebar";

const NavSideBarTrigger = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className }, ref) => {
  const isSidebarOpen = useAtomValue(isNavSidebarOpenAtom);

  return (
    !isSidebarOpen && (
      <div className={className} ref={ref}>
        <SidebarTrigger className="w-10 h-10">
          <Menu strokeWidth={1} />
        </SidebarTrigger>
      </div>
    )
  );
});
NavSideBarTrigger.displayName = "NavSideBarTrigger";

export { NavSidebar, NavSideBarTrigger };
