import { ChatTextarea } from "@/components/ChatTextarea.tsx";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useThread } from "@/hooks/useThreads.tsx";
import { useEffect, useRef, useState } from "react";
// import PersonalDetail from "@/components/messages/PersonalDetail";
// import { cn } from "@/lib/utils";
import MessageDetail from "@/components/messages/MessageDetail.tsx";
import { threadName } from "@/components/threads/util.ts";
import { useIsMobile } from "@/hooks/use-mobile.tsx";
import { useMessages } from "@/hooks/useMessages.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import { ChevronLeft, Feather } from "lucide-react";

const ThreadDetail = ({
  threadId,
  selectedMessageId,
}: {
  selectedMessageId: number;
  threadId: number;
}) => {
  const {
    error: threadError,
    isLoading: isThreadLoading,
    thread,
  } = useThread(threadId);
  const {
    messages,
    isLoading: isMessagesLoading,
    isSending: isSendingMessage,
    error: messagesError,
    sendMessage,
  } = useMessages(threadId);

  const scrollRef = useRef<HTMLDivElement>(null);
  const dummyRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const { userContext } = useCurrentUserContext();
  const isMobile = useIsMobile();

  const handleScroll = () => {
    console.log("handleScroll");
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      // Check if the user is at the bottom or close to it
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10; // Allow a 10px tolerance
      setIsAtBottom(atBottom);
    }
  };

  const handleBackButton = () => {
    window.history.back();
  };

  useEffect(() => {
    if (dummyRef.current && isAtBottom) {
      dummyRef.current.scrollIntoView();
    }
  }, [messages, dummyRef, isAtBottom, isSendingMessage]);

  if (threadError || messagesError) {
    return <Error error={threadError ?? messagesError} />;
  }

  if (isThreadLoading || isMessagesLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="basis-1/8 flex flex-row p-5 items-center gap-2">
        {!isMobile && (
          <Button variant="ghost" size="icon" onClick={handleBackButton}>
            <ChevronLeft className="w-8 h-8" />
          </Button>
        )}
        <h1 className="text-2xl fond-extrabold">{threadName(thread)}</h1>
        <Avatar className="w-4 h-4 shadow-lg">
          <AvatarImage src="/favicon.png" className="grayscale" />
          <AvatarFallback className="bg-slate-500 text-white">
            AI
          </AvatarFallback>
        </Avatar>
        <Feather className="text-gray-200 w-4 h-4 hover:text-gray-800" />
        <div className="ml-auto">
          <Badge className="capitalize bg-gray-200 text-gray-800">
            {thread?.type}
          </Badge>
        </div>
      </div>
      <ScrollArea
        ref={scrollRef}
        className="flex-1 p-4 pb-1 h-full overflow-y-hidden"
        onScroll={handleScroll}
      >
        <div className="flex flex-col gap-2">
          {Array.isArray(messages) &&
            messages?.map((message) => (
              <MessageDetail
                key={message.id}
                message={message}
                selectedMessageId={selectedMessageId}
              />
            ))}
          {isSendingMessage && <LoadingMessage />}
        </div>
        <div ref={dummyRef}></div>
      </ScrollArea>
      <div className="basis-1/8 flex flex-row gap-3 p-4 items-center">
        <AvatarWithProgress
          userContext={userContext}
          isSendingMessage={isSendingMessage}
        />
        <ChatTextarea
          className="flex-grow pt-4"
          onEnterKeyPressed={sendMessage}
          isDisabled={isSendingMessage || isMessagesLoading}
        />
      </div>
    </div>
  );
};

const AvatarWithProgress = ({ userContext, isSendingMessage }) => {
  return (
    <div className="relative">
      {isSendingMessage && (
        <div className="absolute inset-0 -m-1">
          <div
            className="w-full h-full rounded-full border-2 border-transparent border-t-emerald-400 animate-spin"
            style={{
              boxShadow: "0 0 8px rgba(52, 211, 153, 0.6)",
            }}
          />
        </div>
      )}
      <Avatar className="relative">
        <AvatarFallback className="bg-slate-500 text-white">
          {userContext?.user.contact.name.charAt(0)}
        </AvatarFallback>
      </Avatar>
    </div>
  );
};
const LoadingMessage = () => {
  return (
    <div className="p-4 rounded-md flex flex-row gap-4">
      <Avatar className="w-6 h-6">
        <AvatarImage src="/ai_avatar.png" />
        <AvatarFallback className="bg-white text-black">AI</AvatarFallback>
      </Avatar>
      <div className="w-full">
        <div className="p-2 rounded-md bg-white">
          <div className="flex items-center gap-2">
            <div className="flex space-x-2">
              <div className="w-2 h-2 rounded-full bg-purple-300 animate-bounce [animation-delay:-0.3s]">
              </div>
              <div className="w-2 h-2 rounded-full bg-purple-400 animate-bounce [animation-delay:-0.15s]">
              </div>
              <div className="w-2 h-2 rounded-full bg-purple-500 animate-bounce">
              </div>
            </div>
            {/* <span className="text-sm text-gray-500"></span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadDetail;
