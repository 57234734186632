import { Label } from "@/types/label.ts";
import { Thread, ThreadStatus } from "@/types/thread.ts";
import useSWR from "swr";
import useSWRInfinite, { type SWRInfiniteResponse } from "swr/infinite";
import { useLabels } from "./useLabels.tsx";

export const THREAD_PAGE_SIZE = 50;

export const findAndFormatLabel = (labelMapping: Label[], label: string) => {
  const found = labelMapping.find((lm) => lm.id === label);
  const toFilter = ["CATEGORY", "UNREAD", "SENT", "INBOX", "IMPORTANT"];
  const startsWithFilter = ["CATEGORY"];
  if (
    !found || toFilter.includes(found.name) ||
    startsWithFilter.some((prefix) => found.name.startsWith(prefix))
  ) {
    return undefined;
  }

  return {
    name: found.name.toLowerCase().charAt(0).toUpperCase() +
      found.name.toLowerCase().slice(1),
    textColor: found.textColor,
    backgroundColor: found.backgroundColor,
  };
};

export const useThreads = (status?: ThreadStatus) => {
  const getThreadsUrl = (page: number, previousPageData: Array<Thread>) => {
    if (previousPageData && previousPageData.length < THREAD_PAGE_SIZE) {
      return null;
    }
    let url = `/a/threads?limit=${THREAD_PAGE_SIZE}`;
    if (status) {
      url += `&status=${status}`;
    }
    url += `&page=${page}`;
    return url;
  };

  const { labels, isLoading: isLabelsLoading, error: labelsError } =
    useLabels();
  const { data, isLoading, error, mutate, size, setSize, isValidating }:
    SWRInfiniteResponse<Array<Thread>> = useSWRInfinite<Array<Thread>>(
      getThreadsUrl,
      {
        refreshInterval: 30000,
      },
    );

  let errorToReturn = error;
  if (labelsError) {
    errorToReturn = labelsError;
  }

  let isLoadingToReturn = isLoading;
  if (isLabelsLoading) {
    isLoadingToReturn = true;
  }

  let toReturn = data;
  if (!isLoadingToReturn && !errorToReturn) {
    toReturn = data?.map((page) => {
      const threads = Thread.array().parse(page);
      return threads.map((thread) => {
        return {
          ...thread,
          formattedLabels: thread.labels?.map((label) =>
            findAndFormatLabel(labels, label)
          ),
        };
      });
    }) ?? [];
  }

  return {
    error: errorToReturn,
    isLoading: isLoadingToReturn,
    mutate,
    threadPages: toReturn,
    isValidating,
    size,
    setSize,
  };
};

export const useThread = (threadId: number) => {
  const { data: thread, isLoading, error } = useSWR<Thread>(
    `/a/threads/${threadId}`,
  );
  let toReturn: Thread | undefined = thread;
  if (!isLoading && thread) {
    toReturn = Thread.parse(thread);
  }

  return {
    isLoading,
    error,
    thread: toReturn,
  };
};
